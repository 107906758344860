import React from "react"

/**
 * Creates an anchor tag with appropriate security attributes to open external
 * links in a new tab, unless the link is a `mailto:` link, in which case it
 * opens the email client in the same window or tab.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} [props.children=""] - The content of the link.
 * @param {string} [props.href="#"] - The URL that the link will point to.
 * @param {Object} [props.attributes] - Any additional attributes to pass to the link.
 * @returns {JSX.Element} - The JSX element representing the external link.
 *
 * @example
 * <ExternalLink href="https://www.example.com">Click me!</ExternalLink>
 *
 */

export const ExternalLink = ({ children = "", href = "#", ...attributes }) => {
  const isMailto = href.startsWith("mailto:")

  // Add optional classes to the link
  const className = attributes.className
    ? `text-decoration-none ${attributes.className}`
    : "text-decoration-none"

  // Remove className from attributes to prevent overwriting the custom class
  // defined above
  delete attributes.className

  return (
    <a
      className={className}
      href={href}
      rel={isMailto ? undefined : "noopener noreferrer"}
      target={isMailto ? "_self" : "_blank"}
      {...attributes}
    >
      {children}
    </a>
  )
}

import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Form from "react-bootstrap/Form"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { Download, FileWord, FilePdf, FileZip } from "react-bootstrap-icons"
import Seo from "../components/Seo"
import { ExternalLink } from "../components/ui/ExternalLink"

export const Head = () => {
  return <Seo customTitle="Downloads" />
}

export default function downloads({ data }) {
  const DOWNLOADS = data.allFile.edges

  let types = []
  // Push each available file type into an array
  DOWNLOADS.forEach(download => {
    if (!types.includes(download.node.ext)) {
      types.push(download.node.ext)
    }
  })

  function hideFiles(event) {
    const isChecked = event.target.checked
    const fileExt = event.target.id
    const fileList = document.getElementById("files")

    fileList.childNodes.forEach(file => {
      // Check if file extension matches toggled switch
      if (file.className.includes(fileExt)) {
        if (isChecked) {
          file.style.display = "flex"
        } else {
          // "Remove" file from list
          file.style.display = "none"
        }
      }
    })
  }

  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Downloads" />
        <Row>
          <Col xs={12} lg={3} className="mb-5 mb-lg-0">
            <p className="h3 font-weight-bold mb-3">Filter</p>
            <p className="text-dark lh-text">
              Filtern Sie alle Dateien nach beliebigen Dateitypen.
            </p>
            <Form>
              {types.map(type => (
                <Form.Check
                  key={type}
                  type="switch"
                  id={type}
                  label={type}
                  defaultChecked
                  onChange={e => hideFiles(e)}
                />
              ))}
            </Form>
          </Col>
          <Col lg={9} className="border-left border-lg-0">
            <ListGroup variant="flush" id="files">
              {DOWNLOADS.map(download => (
                <ListGroup.Item
                  key={download.node.id}
                  className={`pl-0 pl-lg-2 mb-3 ${download.node.ext}`}
                  style={{ display: "flex" }}
                >
                  {(() => {
                    switch (download.node.ext) {
                      case ".pdf":
                        return <FilePdf className="color-file-pdf display-4" />
                      case ".doc":
                        return (
                          <FileWord className="color-file-word display-4" />
                        )
                      case ".zip":
                        return <FileZip className="text-secondary display-4" />
                      default:
                        return <Download className="text-secondary display-4" />
                    }
                  })()}
                  <p className="text-black-50 ml-5">
                    <span className="d-block h5 text-body font-weight-bold mb-3">
                      {download.node.name}
                    </span>
                    <ExternalLink
                      className="btn btn-sm btn-outline-primary"
                      download
                      href={download.node.publicURL}
                    >
                      Herunterladen ({download.node.prettySize})
                    </ExternalLink>
                  </p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

// Fetch all files from the `downloads` folder
export const query = graphql`
  query downloads {
    allFile(
      filter: { sourceInstanceName: { eq: "downloads" } }
      sort: { fields: birthTime, order: DESC }
    ) {
      edges {
        node {
          id
          publicURL
          ext
          prettySize
          name
        }
      }
    }
  }
`
